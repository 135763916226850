import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { AppInterface, appInterfaceFactory } from '../../AppInterface';
import './SimilarApps.css';
import AppInfo from '../AppInfo/AppInfo';

interface SimilarAppsProps {
  allApps: AppInterface[];
  brand: string;
  domain: string;
  locale: string;
  onCopyClick: (key: string, value: any) => any;
  className?: string;
};

export default function SimilarApps({ allApps, onCopyClick, className, brand, domain, locale }: SimilarAppsProps) {
  const tabsLabels = [
    "Brand",
    "Locale",
    "Domain"
  ];

  const restrictedKeys = [
    'IsCxx',
    'PerceivedLoadMarkers',
    'KeyPages',
    'IsDeleted'
  ];

  const [currentTab, setCurrentTab] = React.useState(1);
  const [similarApps, setSimilarApps] = React.useState([] as AppInterface[]);

  const [delayedCallback] = useDebouncedCallback(() => {
    if (!allApps) {
      return;
    }

    setSimilarApps(allApps.filter(app => {
      const currentTabLabel = tabsLabels[currentTab] as keyof AppInterface;

      switch(currentTabLabel) {
        case "Brand":
          return brand === app.Brand;
        case "Locale":
          return locale === app.Locale;
        case "Domain":
          return domain === app.Domain;
      }
    }));
  }, 300);

  React.useEffect(() => {
    delayedCallback();
  }, [allApps, brand, domain, locale, currentTab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  }

  return (
    <div className={classNames('similar-apps', className)}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
      >
        { tabsLabels.map((label, index) => <Tab key={index} label={label} />) }
      </Tabs>
      <div className="similar-apps__data">
        { similarApps.map((app: AppInterface) => (
          <AppInfo
            onCopyClick={onCopyClick}
            app={app}
            restrictedKeys={restrictedKeys}
            key={app.IdSiteKey || app.AppId} />
        )) }
      </div>
    </div>
  );
}
