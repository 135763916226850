import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import './NewAppStep.css';

interface NewAppStepProps {
  step: number;
};

export default function NewAppStep({ step }: NewAppStepProps) {
  const steps = [
    'Paste your raw data',
    'Amend wrong fields'
  ];

  return (
    <Stepper activeStep={step} alternativeLabel>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
