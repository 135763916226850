import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AppsGridView from './views/AppsGridView/AppsGridView';
import './App.css';
import NewAppView from './views/NewAppView/NewAppView';
import { AppInterface, appInterfaceFactory } from './AppInterface';

const constants = {
  ADOBE_SAAS: {
    API_KEY: process.env.REACT_APP_ADOBE_SAAS_API_KEY || "",
    API_ENDPOINT: process.env.REACT_APP_ADOBE_SAAS_API,
    ADD_NEW_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/addApps`
    },
    GET_APPS_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/brandMetaData`
    },
    DELETE_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/deleteBrand`
    }
  },
  LIBERTY: {
    API_KEY: process.env.REACT_APP_LIBERTY_API_KEY || "",
    API_ENDPOINT: process.env.REACT_APP_LIBERTY_API,
    ADD_NEW_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/addApps`
    },
    GET_APPS_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/brandMetaData`
    },
    DELETE_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/deleteBrand`
    }
  },
  ATH: {
    API_KEY: process.env.REACT_APP_ATH_API_KEY || "",
    API_ENDPOINT: process.env.REACT_APP_ATH_API,
    ADD_NEW_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/addApps`
    },
    GET_APPS_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/brandMetaData`
    },
    DELETE_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/deleteBrand`
    }
  },
  WP_LITE: {
    API_KEY: process.env.REACT_APP_WP_LITE_API_KEY || "",
    API_ENDPOINT: process.env.REACT_APP_WP_LITE_API,
    ADD_NEW_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/addApps`
    },
    GET_APPS_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/brandMetaData`
    },
    DELETE_APP_ENDPOINT: function() {
      return `${this.API_ENDPOINT}/deleteBrand`
    }
  }
};

enum Platform {
  ADOBE_SAAS,
  LIBERTY,
  ATH,
  WP_LITE
};


const App: React.FC = () => {
  const [allApps, setAllApps] = React.useState([] as AppInterface[]);
  const [filteredApps, setFilteredApps] = React.useState([] as AppInterface[]);
  const [platform, setPlatform] = React.useState(Platform[Platform.ADOBE_SAAS]);

  const tabsLabels = [
    "Adobe Saas",
    "Liberty",
    "ATH",
    "Wordpress Lite"
  ];

  const fetchApps = async () => {
    const platformConstants = constants[platform as keyof typeof constants];

    const fetchOptions: RequestInit = {
      headers: { 'X-Api-Key': platformConstants.API_KEY },
      method: 'GET',
      mode: 'cors'
    };

    const response = await fetch(platformConstants.GET_APPS_ENDPOINT(), fetchOptions);
    const apps = await response.json();
    setAllApps(apps.map((app: object) => appInterfaceFactory(app)));
  };

  const handleDeleteButtonClick = (app: AppInterface) => {
    const platformConstants = constants[platform as keyof typeof constants];

    const updatedApp = appInterfaceFactory({ ...app, IsDeleted: true });
    const url = new URL(platformConstants.DELETE_APP_ENDPOINT());
    const params = { Brand: app.Brand, IdSiteKey: app.IdSiteKey };
    url.search = (new URLSearchParams(params)).toString();

    const options: RequestInit = {
      headers: { 'X-Api-Key': platformConstants.API_KEY },
      method: 'GET',
      mode: 'cors'
    };

    fetch(url.toString(), options)
      .then(() => {
        setAllApps([
          ...allApps.slice(0, allApps.indexOf(app)),
          updatedApp,
          ...allApps.slice(allApps.indexOf(app) + 1)
        ]);
      });
  };

  React.useEffect(() => {
    fetchApps();
  }, [platform]);

  React.useEffect(() => {
    setFilteredApps(allApps.filter((app: AppInterface) => !app.IsDeleted));
  }, [allApps]);

  return (
    <div className="App">
      <Switch>
        <Route
          path="/"
          exact
          component={(props: any) => (
            <div className="apps-grid-view">
              <Tabs
                value={Platform[platform as keyof typeof Platform]}
                onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                  setPlatform(Platform[newValue]);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
              >
                { tabsLabels.map((label, index) => <Tab key={index} label={label} />) }
              </Tabs>
              <AppsGridView
                {...props}
                onDeleteButtonClick={handleDeleteButtonClick}
                allApps={filteredApps}
              />
            </div>
          )}
        >
        </Route>

        <Route
          path="/new"
          component={(props: any) => (
            <NewAppView
              {...props}
              allApps={allApps}
              onSubmit={async (apps: AppInterface[]) => {
                const platformConstants = constants[platform as keyof typeof constants];
                const postOptions: RequestInit = {
                  headers: { 'X-Api-Key': platformConstants.API_KEY },
                  method: 'POST',
                  mode: 'cors',
                  body: JSON.stringify(apps.map((app: AppInterface) => app.asJSON()))
                };

                return fetch(platformConstants.ADD_NEW_APP_ENDPOINT(), postOptions);
              }}
              onAddNewApps={() => {
                fetchApps();
              }}
            />
          )}
        >
        </Route>
      </Switch>
    </div>
  );
}

export default App;
