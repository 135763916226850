import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';
import { useDebouncedCallback } from 'use-debounce';

import './AppInfo.css';
import { AppInterface, appInterfaceFactory } from '../../AppInterface';

interface AppInfoProps {
  app: AppInterface;
  onCopyClick?: (key: string, value: any) => any;
  restrictedKeys?: string[];
  displayCopy?: boolean;
};

export default function AppInfo({ app, onCopyClick, restrictedKeys, displayCopy = true }: AppInfoProps) {

  const createRows = () => {
    return Object.keys(app).map((key, index) => {
      if (restrictedKeys && restrictedKeys.includes(key)) {
        return;
      }

      let value = app[key as keyof AppInterface];

      if (Array.isArray(value)) {
        value = value.join(', ');
      }

      return (
        <tr className="app-info__property" key={key}>
          <td className="app-info__property__key">{key}:</td>
          <td className="app-info__property__value" style={{width: '100%'}}>{value}</td>
          <td hidden={!displayCopy}>
            <IconButton
              onClick={() => onCopyClick && onCopyClick(key, value)}
            >
              <FileCopy fontSize="small" />
            </IconButton>
          </td>
        </tr>
      );
    })
  };

  const [properties, setProperties] = React.useState(createRows());
  const [delayedCallback] = useDebouncedCallback(() => {
    setProperties(createRows());
  }, 300);

  React.useEffect(() => {
    delayedCallback();
  }, [onCopyClick]);

  return (
    <div className="app-info">
      <table className="app-info__table">
        <tbody>
          { properties }
        </tbody>
      </table>
    </div>
  );
}
