export class AppInterface {
  [key: string]: any;

  public asJSON() {
    const json = {
      ...this
    };

    delete json.asJSON;

    if (!json.IsDeleted) {
      delete json.IsDeleted;
    }

    return json;
  }
};

export function appInterfaceFactory(app: any): AppInterface {
  const newApp = new AppInterface();

  Object.keys(app).sort().forEach((key) => {
    if (typeof app[key] !== "boolean") {
      newApp[key] = app[key] || "";
    }
  });

  newApp.KeyPages = newApp.KeyPages || [];

  return newApp;
}
