import * as React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import AppForm from '../AppForm/AppForm';
import SimilarApps from '../SimilarApps/SimilarApps';
import './AmendNewApps.css';
import { AppInterface, appInterfaceFactory } from '../../AppInterface';

interface AmendNewAppsProps {
  initialData: AppInterface[];
  onSubmit: (apps: AppInterface[]) => Promise<any>;
  allApps: AppInterface[];
  onFinish: () => any;
};

export default function AmendNewApps({ initialData, onSubmit, allApps, onFinish }: AmendNewAppsProps) {
  const [newApps, setNewApps] = React.useState(initialData);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [currentApp, setCurrentApp] = React.useState(newApps[currentIndex]);
  const [formFields, setFormFields] = React.useState([] as any[]);
  const restrictedKeys = [
    'IsCxx',
    'PerceivedLoadMarkers',
    'KeyPages',
    'Visitors',
    'IsDeleted'
  ];

  React.useEffect(() => {
    setCurrentApp(newApps[currentIndex]);
  }, [newApps, currentIndex]);

  React.useEffect(() => {
    if (!allApps.length) {
      return;
    }

    const newFormFields = Object.keys(allApps[0])
      .filter(key => !restrictedKeys.includes(key) && !Array.isArray(allApps[0][key]))
      .map((key) => {
        const value = allApps[0][key];

        return {
          accessor: key,
          type: typeof value
        };
      });

    setFormFields(newFormFields);

    setCurrentApp(
      appInterfaceFactory({
        ...newFormFields.reduce((acc, field) => (acc[field.accessor] = '', acc), {} as any),
        ...currentApp
      })
    );
  }, [allApps]);

  const handleKeyPagesChange = (newValue: string, index: number) => {
    const updatedKeyPages = [
      ...currentApp.KeyPages.slice(0, index),
      newValue,
      ...currentApp.KeyPages.slice(index + 1, currentApp.KeyPages.length)
    ];

    setCurrentApp(appInterfaceFactory({
      ...currentApp,
      "KeyPages": updatedKeyPages
    }));
  };

  const handleAddKeyPage = () => {
    setCurrentApp(appInterfaceFactory({
      ...currentApp,
      "KeyPages": [...currentApp.KeyPages, ""]
    }));
  };

  const handleRemoveKeyPage = (index: number) => {
    const updatedKeyPages = [
      ...currentApp.KeyPages.slice(0, index),
      ...currentApp.KeyPages.slice(index + 1, currentApp.KeyPages.length)
    ];

    setCurrentApp(appInterfaceFactory({
      ...currentApp,
      "KeyPages": updatedKeyPages
    }));
  };

  const handleIgnoreButtonClick = () => {
    setNewApps([
      ...newApps.slice(0, currentIndex),
      ...newApps.slice(currentIndex + 1, newApps.length)
    ]);
  };

  return (
    <div className="amend-new-apps">
      <MobileStepper
        variant="dots"
        steps={newApps.length}
        position="static"
        activeStep={currentIndex}
        backButton={<span></span>}
        nextButton={<span></span>}
      />
      <div className="amend-new-apps__container">
        <SimilarApps
          className="amend-new-apps__similar-apps"
          allApps={allApps}
          onCopyClick={ (key, value) => setCurrentApp(appInterfaceFactory({ ...currentApp, [key]: value })) }
          brand={currentApp.Brand}
          domain={currentApp.Domain}
          locale={currentApp.Locale}
        />
        <div className="amend-new-apps__app-form-container">
          <AppForm
            className="amend-new-apps__app-form"
            app={currentApp}
            formFields={formFields}
            onChange={(key, newValue) => {
              setCurrentApp(appInterfaceFactory({ ...currentApp, [key]: newValue }));
            }}
            onKeyPagesChange={handleKeyPagesChange}
            onAddKeyPage={handleAddKeyPage}
            onRemoveKeyPage={handleRemoveKeyPage}
            keyPages={currentApp.KeyPages}
          />
          <div className="app-form__buttons">
            <Button
              className="app-form__back"
              variant="contained"
              disabled={newApps.length === 1}
              onClick={handleIgnoreButtonClick}
              color="default">
              Ignore
            </Button>
            <Button
              className="app-form__next"
              variant="contained"
              onClick={() => {
                onSubmit([ currentApp ]).then(() =>{
                  if (newApps.length === 1) {
                    onFinish();
                  } else {
                    setNewApps([
                      ...newApps.slice(0, currentIndex),
                      ...newApps.slice(currentIndex + 1, newApps.length)
                    ]);
                  }
                });
              }}
              color="primary">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
