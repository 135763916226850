import * as React from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormLabel from '@material-ui/core/FormLabel';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

import './AppForm.css';
import { AppInterface, appInterfaceFactory } from '../../AppInterface';

interface AppFormProps {
  app: AppInterface;
  className?: string;
  formFields: any[];
  onChange: (key: string, newValue: string | string[] | number) => any;
  keyPages: string[];
  onKeyPagesChange: (newValue: string, index: number) => any;
  onAddKeyPage: () => any;
  onRemoveKeyPage: (index: number) => any;
};

export default function AppForm({
  app,
  className,
  formFields,
  onChange,
  keyPages,
  onKeyPagesChange,
  onAddKeyPage,
  onRemoveKeyPage
}: AppFormProps) {

  const textFields = formFields.map((field) => {
    return (
      <TextField
        id={field.accessor}
        label={field.accessor}
        type={field.type}
        key={field.accessor}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          const value = field.type === "number" ? parseInt(ev.target.value) : ev.target.value;

          onChange(field.accessor, value);
        }}
        value={app[field.accessor as keyof AppInterface]}
        margin="normal"
      />
    );
  });

  const keyPagesMarkup = keyPages.map((page: string, index: number) => {
    return (
      <div className="app-form__key-page" key={index}>
        <TextField
          id={`key-page-${index}`}
          label="Key page"
          key={index}
          className="app-form__key-page__text-field"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            onKeyPagesChange(ev.target.value, index);
          }}
          value={page}
          margin="normal"
        />
        <IconButton onClick={() => onRemoveKeyPage(index)}>
          <ClearIcon></ClearIcon>
        </IconButton>
      </div>
    )
  });

  return (
    <div className={classNames('app-form', className)}>
      <form className="app-form__form" action="">
        { textFields }
        <div className="app-form__key-pages-header">
          <FormLabel>Key pages</FormLabel>
          <IconButton onClick={() => onAddKeyPage()}>
            <AddIcon></AddIcon>
          </IconButton>
        </div>

        <div className="app-form__key-pages">
          { keyPagesMarkup }
        </div>
      </form>
    </div>
  );
}
