import * as React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import NewAppStep from '../../components/NewAppStep/NewAppStep';
import RawData from '../../components/RawData/RawData';
import AmendNewApps from '../../components/AmendNewApps/AmendNewApps';
import ReviewApps from '../../components/ReviewApps/ReviewApps';

import { AppInterface, appInterfaceFactory } from '../../AppInterface';
import './NewAppView.css';

export default function NewAppView({ match, history, allApps, onAddNewApps, onSubmit }: any) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [newApps, setNewApps] = React.useState([]);

  const parseStr = (newAppsStr: string) => {
    if (!newAppsStr) {
      return;
    }

    let data = JSON.parse(newAppsStr);
    if (!Array.isArray(data)) {
      data = [data];
    }

    setNewApps(data.map((item: object) => appInterfaceFactory(item)));
  }

  return (
    <div className="new-app-view">
      <NewAppStep
        step={currentStep}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          component={() => <Redirect to={'/new/step1'}/>}>
        </Route>

        <Route
          path={`/new/step1`}
          render={(props: any) => {
            setCurrentStep(0);

            return (
              <RawData
                {...props}
                onSubmit={(data) => {
                  parseStr(data);
                  history.push('/new/step2');
                }}
              />
            );
          }}>
        </Route>

        <Route
          path={`/new/step2`}
          render={(props: any) => {
            setCurrentStep(1);

            return (
              <AmendNewApps
                allApps={allApps}
                onSubmit={async (apps: AppInterface[]) => onSubmit(apps)}
                onFinish={() => {
                  props.history.push('/');
                  onAddNewApps();
                }}
                initialData={newApps}
              />
            );
          }}>
        </Route>
      </Switch>
    </div>
  );
}
