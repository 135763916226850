import * as React from 'react';
import './RawData.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

interface RawDataProps {
  onSubmit: (val: string) => any;
}

export default function RawData({ onSubmit }: RawDataProps) {
  const [ newAppsStr, setNewAppsStr ] = React.useState('');

  return (
    <div className="raw-data">
      <div className="raw-data__container">
        <TextField
          id="standard-multiline-flexible"
          label="Paste your JSON here"
          multiline
          rowsMax="25"
          className="raw-data__text-area"
          value={newAppsStr}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setNewAppsStr(ev.target.value)}
          margin="normal"
        />
        <Button
          className="raw-data__submit"
          variant="contained"
          disabled={newAppsStr.length === 0}
          onClick={() => {
            onSubmit(newAppsStr);
          }}
          color="primary">
          Parse data
        </Button>
      </div>
    </div>
  );
}
