import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import './AppsGridHeader.css';

interface AppsGridHeaderProps {
  onFilterChange: (value: string) => any;
};

export default function AppsGridHeader({ onFilterChange }: AppsGridHeaderProps) {
  return (
    <div className="apps-grid-header__container">
      <TextField
        label="Brand filter"
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onFilterChange(ev.target.value)}
        margin="normal"
      />
      <Link to="/new">
        <Button variant="contained" color="primary">
          Add new app
        </Button>
      </Link>
    </div>
  );
}
