import * as React from 'react';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { AppInterface, appInterfaceFactory } from '../../AppInterface';
import _ from 'lodash';
import './AppsGrid.css';

interface AppsGridProps {
  apps: AppInterface[];
  onDeleteButtonClick: (app: AppInterface) => any;
};

interface AppsGridState {
  overscanColumnCount: number;
  overscanRowCount: number;
  scrollToColumn: number | undefined;
  scrollToRow: number | undefined;
  useDynamicRowHeight: boolean;
  fixedRowCount: number;
};

export default function AppsGrid({ apps, onDeleteButtonClick }: AppsGridProps) {
  const defaultGridParams: AppsGridState = {
    overscanColumnCount: 0,
    overscanRowCount: 10,
    scrollToColumn: undefined,
    scrollToRow: undefined,
    useDynamicRowHeight: false,
    fixedRowCount: 1
  };

  const [gridParams, setGridParams] = React.useState(defaultGridParams);
  const [columns, setColumns] = React.useState([] as string[]);
  const [sortedApps, setSortedApps] = React.useState(apps);

  React.useEffect(() => {
    setSortedApps(_.sortBy(apps, 'Brand'));

    if (apps.length > 0) {
      setColumns(Object.keys(apps[0]));
    }
  }, [apps])

  const getApp = (index: number): AppInterface => {
    return sortedApps[index];
  }

  const getRowClassName = (row: number): string => {
    return row % 2 === 0 ? 'apps-grid__row--even' : 'apps-grid__row--odd';
  }

  const renderHeaderCell = ({ columnIndex, key, rowIndex, style }: any): React.ReactNode => {
    return (
      <div className="apps-grid__cell apps-grid__cell--header" key={key} style={style}>
        {columns[columnIndex]}
      </div>
    )
  }

  const renderControlCell = (app: AppInterface, rowClass: string, key: string, style: any): React.ReactNode => {
    return (
      <div className={`apps-grid__cell ${rowClass}`} key={key} style={style}>
        <IconButton
          onClick={() => onDeleteButtonClick(app)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }

  const renderBodyCell = ({ columnIndex, key, rowIndex, style }: any): React.ReactNode => {
    const rowClass = getRowClassName(rowIndex);
    const app = getApp(rowIndex - 1);

    // if (columnIndex === 0) {
    // return renderControlCell(app, rowClass, key, style);
    // }

    let content;

    if (app) {
      content = app[columns[columnIndex] as keyof AppInterface];
    }

    if (Array.isArray(content)) {
      content = content.join(", ");
    }

    return (
      <div className={`apps-grid__cell ${rowClass}`} key={key} style={style}>
        {content}
      </div>
    );
  }

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: any): React.ReactNode => {
    if (rowIndex === 0) {
      return renderHeaderCell({ columnIndex, key, rowIndex, style });
    } else {
      return renderBodyCell({ columnIndex, key, rowIndex, style });
    }
  }

  const getColumnWidth = ({ index }: { index: number }): number => {
    return 200;
  }

  // const getRowHeight = ({ index }: { index: number }): number => {
  //   return index === 0 ? 30 : 50;
  // }

  const noContentRenderer = (): React.ReactNode => {
    return <div className="apps-grid__empty">No cells</div>;
  }

  return (
    <div className="apps-grid__container">
      <AutoSizer>
        {({ width, height }) => (
          <MultiGrid
            cellRenderer={cellRenderer}
            columnWidth={getColumnWidth}
            enableFixedRowScroll
            noContentRenderer={noContentRenderer}
            rowHeight={50}
            rowCount={sortedApps.length + 1}
            width={width}
            height={height}
            columnCount={columns.length}
            {...gridParams}
          />
        )}
      </AutoSizer>
    </div>
  );
}
