import * as React from 'react';

import { AppInterface, appInterfaceFactory } from '../../AppInterface';
import AppsGrid from '../../components/AppsGrid/AppsGrid';
import AppsGridHeader from '../../components/AppsGridHeader/AppsGridHeader';

import './AppsGridView.css';

interface AppsGridViewParams {
  allApps: AppInterface[];
  onDeleteButtonClick: (app: AppInterface) => any;
}

export default function AppsGridView({ allApps, onDeleteButtonClick }: AppsGridViewParams) {
  const [filteredApps, setFilteredApps] = React.useState(allApps);

  const filterApps = (value: string) => {
    const _filteredApps = allApps.filter((item: AppInterface) => {
      return item.Brand.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredApps(_filteredApps);
  }

  return (
    <div className="apps-grid-view__container">
      <AppsGridHeader
        onFilterChange={filterApps}
      />
      <AppsGrid
        apps={filteredApps}
        onDeleteButtonClick={onDeleteButtonClick}
      />
    </div>
  );
}
